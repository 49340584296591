// 闭包节流函数
const throttle = (cb, gap) => {
  let timer;
  return function () {
    let _this = this;
    let args = arguments;
    if (!timer)
      timer = setTimeout(function () {
        timer = null;
        cb.apply(_this, args);
      }, gap);
  };
};
import { areaList } from "@vant/area-data";
export const fieldsMixin = {
  props: {
    injectData: {
      // 需要从外部引入的值 [{name: '张三'}]
      type: Array,
      default: () => []
    },
    externalData: {
      // 需要返回外部的值 ['name', 'idcard]
      type: Array,
      default: () => []
    },
    // 上面几个暂时没做 2024.9.5

    upperCaseKeys: {
      // 需要大写处理的字段
      // 仅限输入框
      type: Array,
      default: () => []
    },
    fields: {
      // 所有字段
      type: [Array, Object],
      default: () => []
    },
    defaultForm: {
      // 默认表单 最终提交数据
      type: [Object, String, null, undefined],
      default: () => {}
    },
    defaultText: {
      // 显示数据
      type: [Object, String, null, undefined],
      default: () => ({})
    },
    formName: {
      // 当前表单的名字，存在的时候，表单校验错误提示会加上该名称
      type: [String],
      default: ""
    },
    formShowTitle: {
      // 当前表单的显示名字，存在的时候，会在表单上部增加一条标题
      // 如果formName不存在，则校验会选取该字段
      type: [String],
      default: ""
    },
    classes: {
      // 类名集合 'class1 class2'
      type: String,
      default: ""
    },
    useCache: {
      // 缓存表单信息
      // 必须存在 formKey 才会生效
      type: Boolean,
      default: false
    },
    formKey: {
      // 用于缓存表单信息key
      type: String,
      default: ""
    },
    disabled: {
      // 禁用整个表单
      type: Boolean,
      default: false
    }
  },
  components: {
    PopupPicker: () => import("../PopupPicker.vue"),
    PopupDatePicker: () => import("../PopupDatePicker.vue"),
    PopupRegionPicker: () => import("../PopupRegionPicker.vue"),
    Upload: () => import("../Upload.vue")
  },
  data() {
    return {
      // 内部计算的表单
      formData: {},
      // 节流管理
      throttleMng: null,
      // 必填
      required: [
        {
          required: true
        }
      ],
      // 输入字段类型
      enterFields: ["text", "textarea", "number", "amount"],
      // 选择字段类型
      selectFields: ["date", "select", "region", "datetime", "radio"],
      // 文件管理
      files: {},

      // 常规选择
      pickerView: false,
      pickerColumns: [],
      useSearch: false,
      // 日期选择
      datePickerView: false,
      datePickerConfig: {},
      // 区域选择
      regionPickerView: false,
      currentCode: "",
      regionKeys: [],
      regionColNum: 3
    };
  },
  watch: {
    formData: {
      deep: true,
      handler(v) {
        this.$emit("updateForm", v); // 手动更新
        this.$emit("update:defaultForm", v); // 自动更新
        const uploadFields = this._fields.filter((f) => f.type == "upload");
        for (let i = 0; i < uploadFields.length; i++) {
          let item = uploadFields[i];
          let key = item.key;
          // 多张图片
          if (item.limit && item.limit > 1 && v[key] && v[key].length) {
            let list = v[key].map((e) => {
              return {
                fileName: e,
                fileUrl: e
              };
            });
            this.files[key] = list;
          }
          // 单张图片
          else if (v[key]) {
            this.files[key] = [
              {
                fileName: v[key],
                fileUrl: v[key]
              }
            ];
          }
        }
      }
    },
    defaultForm: {
      deep: true,
      immediate: true,
      handler(v) {
        this.$set(this, "formData", v);
        this.$nextTick(() => {
          if (this.useCache && this.formKey) this.cacheFormData();
        });
      }
    }
  },
  mounted() {
    //// 如果外部传入表单为空 && (废除该条件)
    // 使用缓存功能 则提取缓存中的字段填充
    if (this.useCache && this.formKey) {
      const data = this.$getFormCache(this.formKey);
      const _data = {
        ...data,
        ...this.formData
      }; // 以外部数据为基准的设置
      this.$nextTick(() => {
        this.$emit("update:defaultForm", _data);
      });
    }
  },
  computed: {
    injKeys() {
      return this.injectData.map((e) => e.key);
    },
    _fields() {
      const { fields } = this.$props;
      return [...fields];
    },
    // 用于展示
    // 没有缓存 textData
    textData: {
      get() {
        let defaultText = this.defaultText || {};
        let computedText = {};
        for (let i = 0; i < this._fields.length; i++) {
          const item = this._fields[i];
          if (item.computedText && typeof item.computedText == "function") {
            computedText[item.key] = item.computedText();
            continue;
          } else if (defaultText[item.key]) {
            computedText[item.key] = defaultText[item.key];
            continue;
          } else if (item.type == "select") {
            let textItem = item.enums?.find(
              (e) => e.value == this.defaultForm[item.key]
            );
            if (textItem?.text) {
              computedText[item.key] = textItem?.text;
            } else {
              computedText[item.key] = this.defaultForm[item.key];
            }
            continue;
          } else if (item.type == "region") {
            const { namesKey, keys } = item;
            let names = [];
            // 从表单中取值
            if (namesKey && namesKey.length)
              namesKey.map((k) => {
                const v = this.defaultForm[k];
                if (v) names.push(v);
              });
            // 如果表单有效值长度与keys长度相同，则从本地数据中计算code-name
            if (names.length != keys.length) {
              let codeKey = keys[keys.length - 1];
              let lastCode = this.formData[codeKey];
              if (lastCode) names = this.getRegionCodeName(lastCode);
            }
            computedText[item.key] = names.join("/");
            continue;
          }
          computedText[item.key] = this.defaultForm[item.key];
        }
        return computedText;
      },
      set(v) {
        this.$emit("updateText", v); // 手动更新
        this.$emit("update:defaultText", v); // 自动更新
      }
    }
  },
  methods: {
    getRegionCodeName(code) {
      let _code = String(code);
      let pcode = _code.slice(0, 2),
        ccode = _code.slice(2, 4),
        acode = _code.slice(4, 6);
      const { province_list, city_list, county_list } = areaList;
      let names = [];
      names.push(province_list[`${pcode}0000`]);
      names.push(city_list[`${pcode}${ccode}00`]);
      if (acode && acode != "00") {
        names.push(county_list[`${pcode}${ccode}${acode}`]);
      }
      return names;
    },
    // 上传类型
    acceptFn(accept) {
      if (accept == "PIC") {
        return ".jpg";
      } else if (accept == "VIDEO") {
        return "video/*";
      } else if (accept == "DOC") {
        return ".pdf,.xlsx,.csv,.xls";
      } else {
        return "image/*,video/*";
      }
    },
    uploadFunction(param, field) {
      const { url } = param;
      if (field.limit && field.limit > 1) {
        let prearr = this.formData[field.key] || [];
        let arr = [...prearr, url];
        this.$set(this.formData, field.key, arr);
      } else {
        this.$set(this.formData, field.key, url);
      }
    },
    deleteFile(index, field) {
      this.files[field.key].splice(index, 1);
      if (field.limit && field.limit > 1) {
        this.formData[field.key].splice(index, 1);
      } else {
        this.$set(this.formData, field.key, "");
      }
    },
    upperCase(key) {
      // 改写为大写
      this.formData[key] = this.formData[key].toLocaleUpperCase();
    },
    getReadonlyVal(field) {
      // 只读
      const { type, key } = field;
      if (this.enterFields.includes(type)) return this.formData[key] || "-";
      else if (this.selectFields.includes(type))
        return this.textData[key] || "-";
    },
    anchorEnums(field) {
      // 选择项点击
      const { type, key } = field;
      if (field.disabled || this.disabled) return;
      this.currentKey = key;
      if (["date", "datetime"].includes(type)) {
        const config = field.config || {};
        const date = this.formData[key];
        if (date) {
          config.currentDate = new Date(date);
        }
        this.datePickerConfig = config;
        this.datePickerView = true;
        return;
      }
      if (["region"].includes(type)) {
        this.regionKeys = field.keys || [];
        this.regionColNum = field.regionColNum || 3;
        this.regionPickerView = true;
        this.currentCode = this.formData[key] || "";
        return;
      }
      const enums = field.enums;
      this.pickerColumns = enums;
      if (field.useSearch) this.useSearch = true;
      else this.useSearch = false;
      this.pickerView = true;
    },
    // 缓存表单信息
    cacheFormData() {
      if (!this.throttleMng) {
        this.throttleMng = throttle(() => {
          this.$setFormCache(this.formData, this.formKey);
        }, 500);
      }
      this.throttleMng();
    },
    // 清除表单信息
    clearCacheFormData() {
      if (this.useCache && this.formKey) {
        this.$removeFormCache(this.formKey);
      }
    },
    // 文本框change事件
    inputChange(val, field) {
      const len = field.decimal;
      if (Object.prototype.hasOwnProperty.call(field, "decimal") && len) {
        val = val.replace(new RegExp(`(\\.\\d{1,${len}})(.*)`, "g"), "$1");
        this.$set(this.formData, field.key, val);
      }

      if (field.change && typeof field.change === "function") field.change(val);

      if (this.useCache && this.formKey) this.cacheFormData();
      this.$emit("change");
    },
    // 文本框失焦事件
    inputBlur(val, field) {
      if (this.upperCaseKeys.includes(field.key)) this.upperCase(field.key);

      if (field.blur && typeof field.blur === "function") field.blur(val);
    },
    // 选择项change事件
    changeFn(item) {
      const field = this.fields.find((e) => e.key == this.currentKey);
      const fn = field.change;
      if (typeof fn == "function") fn(item);
      this.$emit("updateText", this.textData); // 手动更新
      this.$emit("update:defaultText", this.textData); // 自动更新
      if (this.useCache && this.formKey) this.cacheFormData();
    },
    // 常规选择确认
    pickerConfirm(item) {
      const { value, text } = item;
      this.$set(this.formData, this.currentKey, value);
      this.$set(this.textData, this.currentKey, text);
      this.$emit("change", { value, text }, this.currentKey);
      this.changeFn(item);
    },
    // 日期选择确认
    datePickerConfirm(date) {
      this.$set(this.formData, this.currentKey, date);
      this.$set(this.textData, this.currentKey, date);
      this.$emit("change", date, this.currentKey);
      this.changeFn(date);
    },
    // 地区选择确认
    regionPickerConfirm(codes, names) {
      const field = this.fields.find((e) => e.key == this.currentKey);
      const { keys, namesKey } = field;

      if (keys)
        keys.map((k, i) => {
          let v = codes[i];
          this.$set(this.formData, k, v);
        });
      if (namesKey)
        namesKey.map((k, i) => {
          let v = names[i];
          this.$set(this.formData, k, v);
        });
      this.$set(this.textData, this.currentKey, names.join("/"));
      this.$set(this.formData, this.currentKey, codes[codes.length - 1]);
      this.$emit("change", { codes, names }, this.currentKey);
      this.changeFn({ codes, names });
    },
    setPlaceholder(field) {
      const { placeholder } = field;
      if (placeholder) {
        let ifFun = typeof placeholder === "function";
        return ifFun ? placeholder() : placeholder;
      } else {
        const { type, label } = field;
        let pre = this.selectFields.includes(type) ? "请选择" : "请输入";
        return `${pre}${label}`;
      }
    },
    // 表单校验
    validate() {
      const requiredArr = this.fields
        .filter((e) => e.required)
        .map((e) => ({ key: e.key, label: e.label }));
      const validArr = this.fields
        .filter((e) => e.validator)
        .map((e) => ({ key: e.key, validator: e.validator }));
      const formName = this.formName;
      const formShowTitle = this.formShowTitle;
      let pre =
        formName || formShowTitle ? `${formName || formShowTitle}, ` : "";
      // 必填内部校验
      for (let i = 0; i < requiredArr.length; i++) {
        const item = requiredArr[i];
        const v = this.formData[item.key];
        if (!v) throw `${pre}${item.label}不可为空`;
      }
      // 自定义外部校验
      for (let i = 0; i < validArr.length; i++) {
        const item = validArr[i];
        item.validator(this.formData[item.key], pre);
      }
    }
  }
};
